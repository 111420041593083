body {
  margin: 0;
  padding: 0;
}

.App-container {
  min-height: 100vh !important;
}

.App-logo_lg {
  display: block;
  height: 5rem;
  margin: 1rem auto;
}

.App-logo_sm {
  display: block;
  height: 3rem;
  margin: 0.5rem auto;
}

.App-content {
  background: #fff;
  margin: 2rem;
  padding: 2rem;
}
